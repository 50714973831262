export default {
  Jesse_Martin: {
    id: 'Jesse_Martin',
    img: '/speakers/Jesse_Martin.jpg',
    imgSmall: '/speakers-small/Jesse_Martin.png',
    name: 'Jesse Martin',
    position: 'Full-stack Developer at GraphCMS',
    topic: 'Production Ready React with GatsbyJS',
    socialUrls: [
      'https://github.com/motleydev',
      'https://twitter.com/motleydev',
    ],
  },
  Roy_Derks: {
    id: 'Roy_Derks',
    img: '/speakers/Roy_Derks.jpg',
    imgSmall: '/speakers-small/Roy_Derks.png',
    name: 'Roy Derks',
    position: 'Serial Startup CTO, Teacher at reactjs.academy',
    topic: 'Handling Large-Scale State-Management with Context and Hooks',
    socialUrls: [
      'https://github.com/royderks',
      'https://twitter.com/gethackteam',
    ],
  },
  Varik_Matevosyan: {
    id: 'Varik_Matevosyan',
    img: '/speakers/Varik_Matevosyan.jpg',
    imgSmall: '/speakers-small/Varik_Matevosyan.png',
    name: 'Varik Matevosyan',
    position: 'CTO at Steadfast',
    topic: 'Testing React in a right way',
    socialUrls: [
      'https://github.com/var77',
      'https://twitter.com/D4RK7ET',
    ],
  },
  Khachatur_Virabyan: {
    id: 'Khachatur_Virabyan',
    img: '/speakers/Khachatur_Virabyan.jpg',
    imgSmall: '/speakers-small/Khachatur_Virabyan.png',
    name: 'Khachatur Virabyan',
    position: 'React Developer at Renderforest',
    topic: 'Dynamic styling systems with React',
    socialUrls: [
      'https://github.com/Khachatour',
      'https://twitter.com/kh_mugh',
    ],
  },
  Eugene_Zharkov: {
    id: 'Eugene_Zharkov',
    img: '/speakers/Eugene_Zharkov.jpg',
    imgSmall: '/speakers-small/Eugene_Zharkov.png',
    name: 'Eugene Zharkov',
    position: 'React Native Developer',
    topic: 'Monorepo: React Web & React Native',
    socialUrls: [
      'https://github.com/2j2e',
      'https://twitter.com/2j2e',
    ],
  },
  Leandro_Ostera: {
    id: 'Leandro_Ostera',
    img: '/speakers/Leandro_Ostera.jpg',
    imgSmall: '/speakers-small/Leandro_Ostera.png',
    name: 'Leandro Ostera',
    position: 'Senior Web Engineer at SRC',
    topic: 'A Reasonable Web',
    socialUrls: [
      'https://github.com/ostera',
      'https://twitter.com/leostera',
    ],
  },
  Sara_Vieira: {
    id: 'Sara_Vieira',
    img: '/speakers/Sara_Vieira.jpg',
    imgSmall: '/speakers-small/Sara_Vieira.png',
    name: 'Sara Vieira',
    position: 'Developer at CodeSandbox',
    topic: 'Let’s manage our local state with GraphQL',
    socialUrls: [
      'https://github.com/SaraVieira',
      'https://twitter.com/NikkitaFTW',
    ],
  },
  Denys_Radin: {
    id: 'Denys_Radin',
    img: '/speakers/Denys_Radin.jpg',
    imgSmall: '/speakers-small/Denys_Radin.png',
    name: 'Denys Radin',
    position: 'Author of HTML-GL, ReactiveElements, JavaScript/WebGL engineer at Focus Reactive',
    topic: 'Photorealistic 3D graphics on the Web with ReactJS, WebGL and Unity3D',
    socialUrls: [
      'https://github.com/PixelsCommander',
      'https://twitter.com/PixelsCommander',
    ],
  },
  Gagik_Arustamyan: {
    id: 'Gagik_Arustamyan',
    img: '/speakers/Gagik_Arustamyan.jpg',
    imgSmall: '/speakers-small/Gagik_Arustamyan.png',
    name: 'Gagik Arustamyan',
    position: 'Head of Front-end/JavaScript development at SFL',
    topic: 'From design systems to component libraries',
    socialUrls: [
      'https://github.com/arrustamyan',
    ],
  },
  Radoslaw_Malecki: {
    id: 'Radoslaw_Malecki',
    img: '/speakers/Radoslaw_Malecki.jpg',
    imgSmall: '/speakers-small/Radoslaw_Malecki.png',
    name: 'Radoslaw Malecki',
    position: 'Senior Frontend Developer at OLX Group',
    topic: 'Let\'s make a photo booth in React!',
    socialUrls: [
      'https://github.com/radosny',
      'https://twitter.com/radoslawmalecki',
    ],
  },
  Narendra_Shetty: {
    id: 'Narendra_Shetty',
    img: '/speakers/Narendra_Shetty.jpg',
    imgSmall: '/speakers-small/Narendra_Shetty.png',
    name: 'Narendra Shetty',
    position: 'Senior Frontend Developer at Twilio',
    topic: 'Turning an Application UI into an API',
    socialUrls: [
      'https://github.com/narendrashetty',
      'https://twitter.com/narendra_shetty',
    ],
  },
  Nver_Abgaryan: {
    id: 'Nver_Abgaryan',
    img: '/speakers/Nver_Abgaryan.jpg',
    imgSmall: '/speakers/Nver_Abgaryan.jpg',
    name: 'Nver Abgaryan',
    position: 'JavaScript Developer at Hex Division',
    topic: 'The battle of state management: Mobx versus Redux',
    socialUrls: [
      'https://github.com/NverAbgaryan',
    ],
  },
  Cristian_Oliveira_da_Rosa: {
    id: 'Cristian_Oliveira_da_Rosa',
    img: '/speakers/Cristian_Oliveira_da_Rosa.jpg',
    imgSmall: '/speakers-small/Cristian_Oliveira_da_Rosa.png',
    name: 'Cristian Oliveira da Rosa',
    position: 'Software Developer at Sumup.com',
    topic: 'React to the need for speed',
    socialUrls: [
      'https://github.com/cristianoliveira',
      'https://twitter.com/cristian_or_',
    ],
  },
  Elizabet_Oliveira: {
    id: 'Elizabet_Oliveira',
    img: '/speakers/Elizabet_Oliveira.jpg',
    imgSmall: '/speakers-small/Elizabet_Oliveira.png',
    name: 'Elizabet Oliveira',
    position: 'Senior UX Designer at Optum',
    topic: 'An SVG’s Tale',
    socialUrls: [
      'https://github.com/miukimiu',
      'https://twitter.com/miuki_miu',
    ],
  },
  Lilit_Tadevosyan: {
    id: 'Lilit_Tadevosyan',
    img: '/speakers/Lilit_Tadevosyan.jpg',
    imgSmall: '/speakers-small/Lilit_Tadevosyan.png',
    name: 'Lilit Tadevosyan',
    position: 'Lead JavaScript developer at SFL',
    topic: 'Server-Side Rendering with React: Win or Lose',
    socialUrls: [
      'https://github.com/lilittad',
    ],
  },
  Rebecca_Hill: {
    id: 'Rebecca_Hill',
    img: '/speakers/Rebecca_Hill.jpg',
    imgSmall: '/speakers-small/Rebecca_Hill.png',
    name: 'Rebecca Hill',
    position: 'Senior Frontend Developer and Team Lead at WeTransfer',
    topic: 'Creating a Design System that People Actually Use',
    socialUrls: [
      'https://github.com/beerecca',
      'https://twitter.com/rebekaka',
    ],
  },
  Taguhi_Asatryan: {
    id: 'Taguhi_Asatryan',
    img: '/speakers/Taguhi_Asatryan.jpg',
    imgSmall: '/speakers-small/Taguhi_Asatryan.png',
    name: 'Taguhi Asatryan',
    position: 'Full-Stack Developer at Teamable',
    topic: 'Testing React Ecosystem: Do\'s and Don\'ts',
    socialUrls: [
      'https://github.com/Taguhi',
    ],
  },
  Dan_Abramov: {
    id: 'Dan_Abramov',
    img: '/speakers/Dan_Abramov.png',
    imgSmall: '/speakers-small/Dan_Abramov.png',
    name: 'Dan Abramov',
    position: 'Co-author of Redux and Create React App, Working on React at Facebook',
    topic: 'Q&A Session',
    socialUrls: [
      'https://github.com/gaearon',
      'https://twitter.com/dan_abramov',
    ],
  },
};
